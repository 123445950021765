import { MouseEvent, useContext, useState } from 'react';
import FocusLock from 'react-focus-lock';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { NavProps } from '@/components/header/DesktopNav/types';
import FlyoutMenu from '@/components/header/FlyoutMenu';
import HamburgerMenu from '@/components/header/HamburgerMenu';
import { AuthModalContext } from '@/context/AuthModalContextProvider';
import { useAnalytics } from '@/hooks/analytics';
import { useLocale } from '@/hooks/locale/useLocale';
import { useUserState } from '@/hooks/store';
import messages from '@/messages/messages';
import { setAuthFormMode } from '@/store/authFormMode/actions';
import { userCanTrial } from '@/utils/subscriptions';

import { SUPPORT_LINK } from '../Header/constants';
import { NavButton, NavItem, Wrapper } from './styles';

const MobileNav = ({
	isDark,
	freeCtaHref,
	onFreeCtaClick,
	loggedInNavItems: unfilteredNavItems,
	onNavLinkClick,
	query,
	forBusinessHref,
	onForBusinessClick,
	hideMenu,
	useContactButton,
	contactUsHref,
	onContactUsClick,
	hideForBusinessCta,
	hideFreeTrialCta,
	hideHambugerNav = false,
	freeCTAColorBG,
	freeCTAColorText,
	hideAuthCTA,
}: NavProps) => {
	const { calmLanguage } = useLocale();
	const loggedInNavItems = unfilteredNavItems.filter(f =>
		f.supportedLocales ? f.supportedLocales?.includes(calmLanguage) : true,
	);
	const [isOpen, setIsOpen] = useState(false);
	const dispatch = useDispatch();
	const { logEvent } = useAnalytics();
	const { formatMessage } = useIntl();
	const user = useUserState();
	const { setIsModalOpen } = useContext(AuthModalContext);

	const onLoginClick = (e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>): void => {
		e.stopPropagation();
		e.preventDefault();
		logEvent({ eventName: 'Homepage : NavHeader : Login : Clicked' });
		dispatch(setAuthFormMode('login'));
		if (setIsModalOpen) {
			setIsModalOpen(true);
		}
	};

	const onMenuToggle = (): void => {
		setIsOpen(!isOpen);
	};

	const renderLoggedInOptions = (): JSX.Element => {
		return (
			<ul>
				{loggedInNavItems.map(navItem => {
					const href = `${navItem.href}${query}`;
					return (
						<li key={formatMessage(navItem.title)}>
							<NavItem href={href} onClick={(): void => onNavLinkClick(navItem.href)}>
								{formatMessage(navItem.title)}
							</NavItem>
						</li>
					);
				})}
				{!hideFreeTrialCta && !useContactButton && user && userCanTrial(user) && (
					<li>
						<NavItem
							href={`/signup-flow${query}`}
							onClick={(): void => onNavLinkClick(`/signup-flow${query}`)}
						>
							{formatMessage(messages.startFreeTrial)}
						</NavItem>
					</li>
				)}
				{useContactButton && (
					<li>
						<NavItem href={SUPPORT_LINK} onClick={(): void => onNavLinkClick(SUPPORT_LINK)}>
							{formatMessage(messages.contactUs)}
						</NavItem>
					</li>
				)}
				<li>
					<NavItem
						href="/app/profile/stats"
						onClick={(): void => onNavLinkClick(`/app/profile/stats${query}`)}
					>
						{formatMessage(messages.profile)}
					</NavItem>
				</li>
			</ul>
		);
	};

	const renderLoggedOutOptions = (): JSX.Element => {
		return (
			<ul>
				<li>
					<NavItem onClick={onLoginClick} role="button" tabIndex={0}>
						{formatMessage(messages.loginButton)}
					</NavItem>
				</li>
				{!hideForBusinessCta && (
					<li>
						<NavItem href={forBusinessHref} onClick={onForBusinessClick}>
							{formatMessage(messages.forBusiness)}
						</NavItem>
					</li>
				)}
			</ul>
		);
	};

	if (hideMenu) {
		return null;
	}

	const useTryCalm = (!useContactButton && !user && !hideFreeTrialCta) || (hideAuthCTA && !hideFreeTrialCta);
	const hasNonHamburgerNav = useTryCalm || useContactButton;
	return (
		<FocusLock disabled={!isOpen}>
			<Wrapper>
				{hasNonHamburgerNav && !isOpen ? (
					<nav aria-label="secondary">
						<ul>
							{useTryCalm && (
								<li>
									<NavButton
										isDark={Boolean(freeCTAColorText) ?? isDark}
										backgroundColor={freeCTAColorBG ?? 'transparent'}
										textColor={freeCTAColorText ?? isDark ? 'white' : 'black'}
										onPress={onFreeCtaClick}
									>
										{formatMessage(messages.tryCalm)}
									</NavButton>
								</li>
							)}
							{useContactButton && (
								<li>
									<NavButton
										isDark={isDark}
										backgroundColor="transparent"
										textColor={isDark ? 'white' : 'black'}
										href={contactUsHref}
										onPress={onContactUsClick}
									>
										{formatMessage(messages.contactUs)}
									</NavButton>
								</li>
							)}
						</ul>
					</nav>
				) : null}
				{!hideHambugerNav && <HamburgerMenu onClick={onMenuToggle} isDark={isDark} isOpen={isOpen} />}
			</Wrapper>
			<FlyoutMenu isOpen={isOpen} label="primary">
				{user ? renderLoggedInOptions() : renderLoggedOutOptions()}
			</FlyoutMenu>
		</FocusLock>
	);
};

export default MobileNav;
