import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import partnerMessages from '@/components/SimplifiedSignup/configs/Partnerships/messages';
import AuthTerms from '@/components/authForm/AuthTermsRefresh';
import { usePartnerState } from '@/hooks/store';

import { FooterText } from './styles';

const PartnerSpecificAuthTerms = ({ terms }: { terms?: string }) => {
	const { formatMessage } = useIntl();
	const partner = usePartnerState();
	const isAARP = partner?.name.toLowerCase() === 'aarp';

	return (
		<>
			{isAARP && (
				<>
					<br />
					<FooterText>
						{formatMessage(partnerMessages.aarp_membership, {
							join: (...chunks: ReactNode[]) => (
								<a
									href="https://appsec.aarp.org/mem/join?campaignId=FFPUCALM&cmp=ASI_P_MU_JN_CALM"
									target="_blank"
									rel="noreferrer"
								>
									{chunks}
								</a>
							),
							renew: (...chunks: ReactNode[]) => (
								<a
									href="https://appsec.aarp.org/mem/renew?campaignId=FFPUCALM&cmp=ASI_P_MU_RN_CALM"
									target="_blank"
									rel="noreferrer"
								>
									{chunks}
								</a>
							),
						})}
					</FooterText>
					<br />
				</>
			)}
			<br />
			<AuthTerms loginTermsText={terms} signupTermsText={terms as string} />
		</>
	);
};

export default PartnerSpecificAuthTerms;
