import { PlanCadence } from '@/store/purchaseParams/types';

export interface Coupon {
	id: string;
	duration: string;
	percent_off?: number;
	amount_off?: number;
}

type Coupons = Partial<{
	[key in PlanCadence]: Coupon;
}>;

export interface PriceOptions {
	monthly: number;
	yearly: number;
	lifetime: number;
	currency: string;
	amount_due?: number;
}

export interface PricingFormat {
	format?: string;
	seperator?: string;
	currency: string;
}

export interface Prices {
	current: PriceOptions;
	original: PriceOptions;
	coupons: Coupons;
	pricing_format: PricingFormat;
}

export const SET_PRICES = 'SET_PRICES';

export interface SetPricesAction {
	type: typeof SET_PRICES;
	payload: Prices;
}

export const SET_PRICE = 'SET_PRICE';

export interface SetPriceAction {
	type: typeof SET_PRICE;
	payload: Partial<Prices>;
}
