import { Dispatch } from 'react';

import { AuthFormMode } from '@/store/authFormMode/types';
import { PlanCadence } from '@/store/purchaseParams/types';
import { User } from '@/store/user/types';

import { B2BPartnerError } from '../Error/types';
import { partners } from './data';

export const IntegrationType = {
	ACCESS_CODES: 'ACCESS_CODES',
	API: 'API',
	ELIGIBILITY_FILE: 'ELIGIBILITY_FILE',
	SSO: 'SSO',
	GROUP_CODE: 'GROUP_CODE',
} as const;
// eslint-disable-next-line no-redeclare
export type IntegrationType = (typeof IntegrationType)[keyof typeof IntegrationType];

export enum PartnerCategory {
	CALM_FOR_BUSINESS = 'CALM_FOR_BUSINESS',
	SALES_TRIAL = 'SALES_TRIAL',
	TELCO_PARTNERSHIP = 'TELCO_PARTNERSHIP',
	BRAND_PARTNERSHIP = 'BRAND_PARTNERSHIP',
	HEALTH_CARE_PROVIDER = 'HEALTH_CARE_PROVIDER',
	D2C_LEAD_GEN_PARTNERSHIP = 'D2C_LEAD_GEN_PARTNERSHIP',
}
export interface PartnerDetails {
	id: string;
	name: string;
	requires_data_sharing: boolean;
	slug: string;
	category?: PartnerCategory;
	supports_eligibility_list: boolean;
	user_id_descriptor?: string;
	web_renew_url?: string;
	integration_type: IntegrationType;
	contract_expires_at?: string;
	offer_details?: DTCOfferDetails;
	should_show_request_access_flow?: boolean;
	logo_url?: string;
}

type Partner = keyof typeof partners;
export interface PartnerData extends PartnerDetails {
	pageInfo: (typeof partners)[Partner];
}

export interface Dependent {
	id?: string;
	calm_user_id?: string;
	email: string;
	redeemed_at?: string;
}

export type B2BReferer = 'access' | 'error' | 'subscribe' | 'renew' | 'zoom';

export interface LandingProps {
	error?: B2BPartnerError;
	partnerSlug: string;
	referer: B2BReferer;
	linkToken?: string;
	rawPartner: PartnerDetails | null;
}

export interface LandingDisplayProps {
	Component: React.FC<SignupScreenProps> | null;
	activeScreen: SignupScreensKey;
	partnerDetails: PartnerData;
	errorData: B2BPartnerError | undefined;
	onScreenChange: OnDTCScreenChange;
	onAuthSuccess: (user: User) => Promise<void>;
	redeem: (
		partnerDetails: PartnerData,
		uniqueId?:
			| { partner_user_id?: string; puid_token?: undefined; group_code?: string }
			| { partner_user_id?: undefined; puid_token: string; group_code?: string },
	) => Promise<void>;
	requestAccessCall?: (id: string, firstName: string, lastName: string, email: string) => void;
	isSubmitting: boolean;
	supportUrl: string | null;
	name: string;
	setUserAccessCode?: Dispatch<string>;
	userAccessCode?: string;
	renderAsDtcPartner?: boolean;
}

const signupScreenKeys = [
	'account',
	'eligibility',
	'error',
	'payment',
	'sorry',
	'success',
	'welcome',
] as const;
export type SignupScreensKey = (typeof signupScreenKeys)[number];
export const isSignupScreenKey = (value: string): value is SignupScreensKey =>
	signupScreenKeys.includes(value as SignupScreensKey);
export interface OnDTCScreenChange {
	(screen: SignupScreensKey, mode?: AuthFormMode): void;
}

export type SignupScreenProps = {
	onScreenChange: OnDTCScreenChange;
	redeem: LandingDisplayProps['redeem'];
	requestAccessCall?: (id: string, firstName: string, lastName: string, email: string) => void;
	error?: B2BPartnerError;
	supportUrl?: string | null;
	isSubmitting: boolean;
	partnerDetails: PartnerData;
	onSuccess: (user: User) => void;
	name: string;
	compact?: boolean;
	userAccessCode?: string;
	setUserAccessCode?: Dispatch<string>;
};

export interface DTCOfferDetails {
	amount_due?: number;
	billing_plan?: string;
	country?: string;
	currency?: string;
	interval?: string;
	original_price?: number;
	percent_off?: number | null;
	price?: number;
	recurring?: boolean;
	trial_duration?: TrialDuration | null;
	d2c_subscription_plan?: PlanCadence;
	terms?: string;
	duration_in_months?: number;
	stripe_coupon?: string;
}

export enum TrialDuration {
	SEVEN_DAYS = 'P7D',
	FOURTEEN_DAYS = 'P14D',
	ONE_MONTH = 'P1M',
	TWO_MONTHS = 'P2M',
	THREE_MONTHS = 'P3M',
	FOUR_MONTHS = 'P4M',
	SIX_MONTHS = 'P6M',
	TWELVE_MONTHS = 'P12M',
}

export type TrialDurationMapType = {
	[TrialDuration.SEVEN_DAYS]: {
		duration: number;
		unit: 'day' | 'month' | 'week' | 'year' | null | undefined;
	};
	[TrialDuration.FOURTEEN_DAYS]: {
		duration: number;
		unit: 'day' | 'month' | 'week' | 'year' | null | undefined;
	};
	[TrialDuration.ONE_MONTH]: {
		duration: number;
		unit: 'day' | 'month' | 'week' | 'year' | null | undefined;
	};
	[TrialDuration.TWO_MONTHS]: {
		duration: number;
		unit: 'day' | 'month' | 'week' | 'year' | null | undefined;
	};
	[TrialDuration.THREE_MONTHS]: {
		duration: number;
		unit: 'day' | 'month' | 'week' | 'year' | null | undefined;
	};
	[TrialDuration.FOUR_MONTHS]: {
		duration: number;
		unit: 'day' | 'month' | 'week' | 'year' | null | undefined;
	};
	[TrialDuration.SIX_MONTHS]: {
		duration: number;
		unit: 'day' | 'month' | 'week' | 'year' | null | undefined;
	};
	[TrialDuration.TWELVE_MONTHS]: {
		duration: number;
		unit: 'day' | 'month' | 'week' | 'year' | null | undefined;
	};
};

export const trialDurationMap: TrialDurationMapType = {
	P7D: {
		duration: 7,
		unit: 'day',
	},
	P14D: {
		duration: 14,
		unit: 'day',
	},
	P1M: {
		duration: 1,
		unit: 'month',
	},
	P2M: {
		duration: 2,
		unit: 'month',
	},
	P3M: {
		duration: 3,
		unit: 'month',
	},
	P4M: {
		duration: 4,
		unit: 'month',
	},
	P6M: {
		duration: 6,
		unit: 'month',
	},
	P12M: {
		duration: 12,
		unit: 'month',
	},
};
