import { useRouter } from 'next/router';
import { useContext, useState } from 'react';

import {
	SimplifiedSignupContextProvider,
	useSimplifiedSignupContext,
} from '@/components/SimplifiedSignup/Context';
import ControlFlow from '@/components/SimplifiedSignup/configs/ControlModal/Flow';
import UpsellFooter from '@/components/UpsellFooter';
import SkipToMain, { MAIN_CONTENT_ID } from '@/components/accessibility/SkipToMain';
import AudioPlayer from '@/components/app/contentPlayer/AudioPlayer';
import { MediaPlayerContext } from '@/components/app/contentPlayer/MediaPlayerProvider';
import VideoPlayer from '@/components/app/contentPlayer/VideoPlayer';
import AppBackground from '@/components/app/layout/AppBackground';
import DiscountModal from '@/components/app/layout/DiscountModal';
import Header from '@/components/app/layout/HeaderRefresh';
import { ShareContext } from '@/components/app/shareFreeContent/ShareProvider';
import Sidebar from '@/components/app/sidebar/Sidebar';
import { useAppLoadedState, useThemeState } from '@/hooks/store';
import { isTeamsPath } from '@/utils/app/msftTeams';
import { calmLogger } from '@/utils/calmLogger';

import { CookiePreferencesBannerContext } from '../CookiePreferencesBannerProvider';
import { OfferBannerContext } from '../OfferBannerProvider';
import ShareContentBanner from '../ShareContentBanner';
import ShareContentModal from '../ShareContentModal';
import { menuItems, teamsMenuItems } from './data';
import { Container, Main, Wrapper } from './styles';
import { AppLayoutProps } from './types';

const AppLayout = ({ children, appHeaderProps }: AppLayoutProps) => {
	const appLoaded = useAppLoadedState();
	const router = useRouter();
	const theme = useThemeState();
	const { onShowSharePayment, sidebarCta, isShareContentRecipient } = useContext(ShareContext);
	const { onPause, contentType, isZenMode, playerMode } = useContext(MediaPlayerContext);
	const { fixedBottomHeight } = useContext(CookiePreferencesBannerContext);
	const { bannerComponent } = useContext(OfferBannerContext);
	const { flowConfig = ControlFlow } = useSimplifiedSignupContext();

	const [isNavOpen, setIsNavOpen] = useState(false);

	const onShareBannerCtaPress = () => {
		if (playerMode === 'playing') {
			onPause();
		}
		onShowSharePayment('banner').catch(error =>
			calmLogger.error('Error in AppLayout onShowSharePayment', {}, error),
		);
	};

	// eslint-disable-next-line react/jsx-no-useless-fragment
	if (!theme.webAppLayout) return <>{children}</>;

	const isExternalApp = theme.isZoomApp || (theme.isTeamsApp && !theme.teamsShowHeaderAndSidebar);

	const isTeamsRoute = isTeamsPath(router.pathname);

	return (
		<>
			<SkipToMain href={`#${MAIN_CONTENT_ID}`} />
			<AppBackground isZenMode={isZenMode} />
			<Wrapper
				$isZenMode={isZenMode}
				$isLargePlayer={theme.webAppLayout === 'player'}
				$isTeamsApp={theme.isTeamsApp}
				$isBannerVisible={Boolean(bannerComponent)}
			>
				{!isExternalApp && (
					<Sidebar
						menuItems={isTeamsRoute ? teamsMenuItems : menuItems}
						upsellButtonCopy={isShareContentRecipient ? sidebarCta : undefined}
						onUpsellPress={isShareContentRecipient ? () => onShowSharePayment('sidebar') : undefined}
						isUpsellButtonVisible={isShareContentRecipient}
						bottomOffset={fixedBottomHeight}
					/>
				)}
				<Main id={MAIN_CONTENT_ID} $isZoomApp={isExternalApp}>
					<SimplifiedSignupContextProvider flowConfig={flowConfig}>
						{!isExternalApp && (
							<Header isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} appHeaderProps={appHeaderProps} />
						)}
						<Container
							$isVisible={appLoaded}
							$isZoomApp={isExternalApp}
							$hasBanner={isShareContentRecipient}
							$bottomOffset={fixedBottomHeight}
							$isLargePlayer={theme.webAppLayout === 'player'}
						>
							<ShareContentBanner
								showShareContent={isShareContentRecipient}
								onPress={onShareBannerCtaPress}
							/>
							<ShareContentModal />

							{children}
						</Container>
					</SimplifiedSignupContextProvider>
				</Main>
				{contentType === 'video' && <VideoPlayer />}
				{contentType === 'audio' && <AudioPlayer />}
			</Wrapper>
			<DiscountModal />
			<UpsellFooter />
		</>
	);
};

export default AppLayout;
