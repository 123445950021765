import { ReactNode } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';

import { FontSize, FontSizes, FontWeights, Text } from '@calm-web/design-system';

import LimitedTimeCallout from '@/components/PreSignupFlow/Components/LimitedTimeCallout';
import messages from '@/components/SimplifiedSignup/Account/messages';
import paymentMessages from '@/components/SimplifiedSignup/Payment/messages';
import partnerMessages from '@/components/SimplifiedSignup/configs/Partnerships/messages';
import { useFormattedCurrency } from '@/hooks/purchase';
import { useAuthFormModeState, usePartnerState, usePricesState, useUserState } from '@/hooks/store';
import useQueryParams from '@/hooks/utils/useQueryParams';

// import AuthForm from '@/components/authForm/AuthFormRefresh';
import AuthForm from '../ConfigurableAuthForm';

type AuthFormWrapperProps = {
	customEventNameProp: string;
	isLimitedTimeOffer?: boolean;
	heading?: (authFormMode: string) => {
		message: MessageDescriptor;
		placeholders?: { [key: string]: string | number | boolean };
	};
	headingSize?: FontSize;
	loginTerms?: MessageDescriptor;
	signupTerms?: MessageDescriptor;
	partnerTerms?: string | MessageDescriptor;
	hideSocialSignup?: boolean;
	hideAuthModeToggle?: boolean;
	emailSignupCTA?: MessageDescriptor;
	subheading?: string;
};

const AuthFormWrapper = ({
	customEventNameProp,
	isLimitedTimeOffer,
	heading,
	headingSize,
	loginTerms,
	signupTerms,
	partnerTerms,
	hideSocialSignup = false,
	hideAuthModeToggle = false,
	emailSignupCTA,
	subheading,
}: AuthFormWrapperProps) => {
	const { formatMessage, formatDate } = useIntl();
	const formatCurrency = useFormattedCurrency();
	const prices = usePricesState();
	const authFormMode = useAuthFormModeState();
	const partner = usePartnerState();
	const user = useUserState();
	const query = useQueryParams();
	const isSimplifiedFTUE: boolean = query?.utm_content?.includes('_ft-sf') || false;
	const isIneligiblePartnerUser = user?.subscription?.valid && partner;

	function buildHeading() {
		if (heading) {
			if (isSimplifiedFTUE && authFormMode === 'signup') {
				return formatMessage(messages.signupFormSimpleHeading);
			}
			const { message, placeholders = {} } = heading(authFormMode);
			return formatMessage(message, placeholders);
		}
	}

	const formatTerms = (terms: MessageDescriptor | undefined) => {
		if (!terms) return '';
		return formatMessage(terms, {
			date: partner?.contract_expires_at
				? formatDate(new Date(partner?.contract_expires_at ?? ''), { dateStyle: 'medium' })
				: '',
			originalPrice: formatCurrency(
				prices.original[partner?.offer_details?.d2c_subscription_plan || 'yearly'],
			),
			cancel: (...chunks: ReactNode[]) => (
				<a
					href="https://support.calm.com/hc/en-us/articles/115002473607-How-to-turn-off-auto-renewal-or-cancel-my-subscription"
					target="_blank"
					rel="noreferrer"
				>
					{chunks}
				</a>
			),
			a: (...chunks: ReactNode[]) => (
				<a href="/terms" target="_blank">
					{chunks}
				</a>
			),
		});
	};

	return !isIneligiblePartnerUser ? (
		<div>
			{isLimitedTimeOffer && (
				<LimitedTimeCallout>{formatMessage(paymentMessages.limitedTimeCallout)}</LimitedTimeCallout>
			)}
			{heading && (
				<Text
					align="center"
					noMargin
					el="h1"
					weight={FontWeights.Medium}
					size={headingSize || FontSizes['2xl']}
				>
					{buildHeading()}
				</Text>
			)}
			<AuthForm
				loginTermsText={loginTerms && formatTerms(loginTerms)}
				signupTermsText={signupTerms && formatTerms(signupTerms)}
				partnerTerms={partnerTerms as string}
				showMarketingOptIn={false}
				customEventProps={{ source: customEventNameProp }}
				hideFacebookSignup={hideSocialSignup}
				hideGoogleSignup={hideSocialSignup}
				hideAppleSignup={hideSocialSignup}
				hideAuthModeToggle={hideAuthModeToggle}
				emailSignupCTA={emailSignupCTA}
				subheading={subheading}
			/>
		</div>
	) : (
		<div>
			<Text el="h4">{formatMessage(partnerMessages.sorry)}</Text>
			<Text el="p">
				{formatMessage(partnerMessages.userHasSubscription, {
					managesubscriptionlink: (...chunks: ReactNode[]) => (
						<>
							<br />
							<a href="/profile/manage-subscription" target="_blank" rel="noopener noreferrer">
								{chunks}
							</a>
						</>
					),
				})}
			</Text>
		</div>
	);
};

export default AuthFormWrapper;
