import { combineReducers } from 'redux';

import { PartnerDetails as Partner } from '@/components/b2b/shared/types';
import { ApiPartnerResponse } from '@/server/initState/getPartner';

// Reducers
import { appLoadedReducer } from './appLoaded/reducers';
import { AppLoaded } from './appLoaded/types';
import { authFormModeReducer } from './authFormMode/reducers';
import { AuthFormMode } from './authFormMode/types';
import { browserLanguageReducer } from './browserLanguage/reducers';
import { BrowserLanguage } from './browserLanguage/types';
import { campaignReducer } from './campaign/reducers';
import { Campaign } from './campaign/types';
import { complianceLevelReducer } from './complianceLevel/reducers';
import { ComplianceLevel } from './complianceLevel/types';
import { deviceReducer } from './device/reducers';
import { CallToAction, Device } from './device/types';
import { featureFlagsReducer } from './feature-flags/reducers';
import { FeatureFlags } from './feature-flags/types';
import { guideReducer } from './guide/reducers';
import { Guide } from './guide/types';
import { localeReducer } from './locale/reducers';
import { Locale } from './locale/types';
import { notificationReducer } from './notification/reducers';
import { Notification } from './notification/types';
import { partnerReducer } from './partner/reducers';
import { pricesReducer } from './prices/reducers';
import { Prices } from './prices/types';
import { programReducer } from './program/reducers';
import { Program } from './program/types';
import { purchaseParamsReducer } from './purchaseParams/reducers';
import { PurchaseParams } from './purchaseParams/types';
import { recaptchaVisibleReducer } from './recaptchaVisible/reducers';
import { RecaptchaVisible } from './recaptchaVisible/types';
import { serverSideReducer } from './serverSide/reducers';
import { ServerSide } from './serverSide/types';
import { themeReducer } from './theme/reducers';
import { Theme } from './theme/types';
import { userReducer } from './user/reducers';
import { User } from './user/types';
import { zoomReducer } from './zoom/reducers';
import { Zoom } from './zoom/types';

// TODO: Add in the nunce to app state to allow javascript to run
export type AppInitialState = {
	appLoaded: AppLoaded;
	authFormMode?: AuthFormMode;
	browserLanguage: BrowserLanguage;
	campaign?: Campaign;
	complianceLevel: ComplianceLevel;
	device: Device;
	featureFlags: FeatureFlags;
	guide?: Guide | null;
	locale?: Locale;
	notification?: Notification | null;
	partner?: Partner | null;
	prices?: Prices;
	program?: Program | null;
	purchaseParams: PurchaseParams;
	recaptchaVisible?: RecaptchaVisible;
	serverSide: ServerSide;
	theme?: Theme;
	user?: User | null;
	zoom?: Zoom | null;
};

export type InitialServerState = {
	appLoaded: AppLoaded;
	complianceLevel: ComplianceLevel;
	device?: Device;
	featureFlags: FeatureFlags;
	partner: Partner | ApiPartnerResponse | null;
	prices?: Prices;
	purchaseParams: PurchaseParams;
	serverSide: ServerSide;
	theme: Theme;
	user: User | null;
	locale: string;
	program: Program | null;
	guide: Guide | null;
};

export type OAuthCustomDeviceCall = {
	call_to_action?: CallToAction | null;
	user?: User | null;
};

export type AppState = {
	appLoaded: AppLoaded;
	authFormMode: AuthFormMode;
	browserLanguage: BrowserLanguage | null;
	campaign: Campaign;
	complianceLevel: ComplianceLevel;
	device: Device;
	featureFlags: FeatureFlags;
	guide: Guide | null;
	locale: Locale | null;
	notification: Notification | null;
	partner: Partner | null;
	prices: Prices; // TODO: Make this nullable
	program: Program | null;
	purchaseParams: PurchaseParams;
	recaptchaVisible: RecaptchaVisible;
	serverSide: ServerSide;
	theme: Theme;
	user: User | null;
	zoom: Zoom | null;
};

export const rootReducer = combineReducers<AppState>({
	appLoaded: appLoadedReducer,
	authFormMode: authFormModeReducer,
	browserLanguage: browserLanguageReducer,
	campaign: campaignReducer,
	complianceLevel: complianceLevelReducer,
	device: deviceReducer,
	featureFlags: featureFlagsReducer,
	guide: guideReducer,
	locale: localeReducer,
	notification: notificationReducer,
	partner: partnerReducer,
	prices: pricesReducer,
	program: programReducer,
	purchaseParams: purchaseParamsReducer,
	recaptchaVisible: recaptchaVisibleReducer,
	serverSide: serverSideReducer,
	theme: themeReducer,
	user: userReducer,
	zoom: zoomReducer,
});
