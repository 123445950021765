import { MessageDescriptor, defineMessages } from 'react-intl';

import { isWithinDateRange } from '.';

export type BannerConfigType = {
	longCopy: MessageDescriptor;
	url: string;
	ctaText: MessageDescriptor;
	textColor: string;
	backgroundColor: string;
	onClose: () => void;
	startDate: string;
	endDate: string;
	visiblePaths: string[];
	coupon?: string;
};

export const messages = defineMessages({
	copyPreBF: {
		id: 'offer.banner.copyPreBF',
		defaultMessage: 'Unlock big savings! Get 50% off',
		description: 'Description of offer',
	},
	copyBF: {
		id: 'offer.banner.copyBF',
		defaultMessage: 'Our best deal is back! Get 50% off',
		description: 'Description of offer',
	},
	copyDec: {
		id: 'offer.banner.copyDec',
		defaultMessage: 'Stay calm this season with 50% off!',
		description: 'Description of offer',
	},
	copyEarlyJan: {
		id: 'offer.banner.copyEarlyJan',
		defaultMessage: 'Stress less all year long with 40% off!',
		description: 'Description of offer',
	},
	copyLateJan: {
		id: 'offer.banner.copyLateJan',
		defaultMessage: 'Last chance to get 50% off!',
		description: 'Description of offer',
	},
	ctaText: {
		id: 'offer.banner.CTA',
		defaultMessage: 'Get Offer Now',
		description: 'Description of offer',
	},
});

export const configBase = {
	ctaText: messages.ctaText,
	textColor: '#fff',
	backgroundColor: '#1C2056',
	url: '/annual-offer',
	onClose: () => {},
};
export const PreBFConfig = {
	...configBase,
	longCopy: messages.copyPreBF,
	coupon: 'hol2023_Product_annual50_WebBanner',
	startDate: '10/29/2023',
	endDate: '11/03/2023',
	visiblePaths: ['/'],
};
export const BFConfig = {
	...configBase,
	longCopy: messages.copyBF,
	coupon: 'hol2023_Product_annual50_WebBanner',
	startDate: '11/15/2023',
	endDate: '11/27/2023',
	visiblePaths: ['/'],
};
export const DecConfig = {
	...configBase,
	longCopy: messages.copyDec,
	coupon: 'hol2023_Product_annual50_WebBanner',
	startDate: '12/10/2023',
	endDate: '12/16/2023',
	visiblePaths: ['/'],
};
export const EarlyJanConfig = {
	...configBase,
	longCopy: messages.copyEarlyJan,
	coupon: 'hol2024_Product_annual40_WebBanner',
	startDate: '01/01/2024',
	endDate: '01/20/2024',
	visiblePaths: ['/'],
};
export const LateJanConfig = {
	...configBase,
	longCopy: messages.copyLateJan,
	coupon: 'hol2023_Product_annual50_WebBanner',
	startDate: '01/21/2024',
	endDate: '01/31/2024',
	visiblePaths: ['/'],
};

export const bannerConfigs = [PreBFConfig, BFConfig, DecConfig, EarlyJanConfig, LateJanConfig];

export function getActiveConfig(): BannerConfigType {
	const filtered = bannerConfigs.filter(({ startDate, endDate }) => {
		return isWithinDateRange(startDate, endDate);
	});
	return filtered[0] || null;
}
